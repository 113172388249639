import { useState } from "react";
import "./App.css";
import SuperTokens, { SuperTokensWrapper, getSuperTokensRoutesForReactRouterDom } from "supertokens-auth-react";
import ThirdPartyPasswordless from "supertokens-auth-react/recipe/thirdpartypasswordless";
import Session from "supertokens-auth-react/recipe/session";
import Home from "./Home";
import { Routes, BrowserRouter as Router, Route, useParams } from "react-router-dom";
import { redirectToAuth } from "supertokens-auth-react/recipe/thirdpartypasswordless";
import SessionExpiredPopup from "./SessionExpiredPopup";

const Login = () => {
	return <div onClick={redirectToAuth({ redirectBack: true })}>Login</div>;
};

export function getApiDomain() {
	const apiUrl = process.env.REACT_APP_API_URL || `https://dapi.dev.captainfresh.in`;
	return apiUrl;
}

export function getWebsiteDomain() {
	console.log(window.location, "window");
	const websiteUrl = process.env.REACT_APP_WEBSITE_URL || `https://realm-dev.captainfresh.in`;
	return websiteUrl;
}

export function getRedirectionUrlForUser() {
	let redirectPath = localStorage.getItem("redirectPath");
	return redirectPath ? redirectPath : "";
}

SuperTokens.init({
	appInfo: {
		appName: "Realm",
		apiDomain: getApiDomain(),
		apiGatewayPath: "/realm",
		websiteDomain: getWebsiteDomain(),
		apiBasePath: "/auth",
	},
	recipeList: [
		ThirdPartyPasswordless.init({
			emailVerificationFeature: {
				mode: "REQUIRED",
			},
			signInUpFeature: {
				providers: [ThirdPartyPasswordless.Google.init()],
				defaultCountry: "IN",
			},
			contactMethod: "PHONE",
			getRedirectionURL: async (context) => {
				if (context.action === "SUCCESS") {
					console.log(context);
					const redirectionUrl = getRedirectionUrlForUser();
					return redirectionUrl;
				}
			},
		}),

		Session.init({
			sessionScope: ".captainfresh.in",
		}),
	],
});

function App() {
	let [showSessionExpiredPopup, updateShowSessionExpiredPopup] = useState(false);

	return (
		<SuperTokensWrapper>
			<div className="App">
				<Router>
					<div className="fill">
						<Routes>
							{getSuperTokensRoutesForReactRouterDom(require("react-router-dom"))}
							<Route path="/login" element={<Login />}></Route>
							<Route
								path="/"
								element={
									<ThirdPartyPasswordless.ThirdPartyPasswordlessAuth
										onSessionExpired={() => {
											updateShowSessionExpiredPopup(true);
										}}
									>
										<Home />
										{showSessionExpiredPopup && <SessionExpiredPopup />}
									</ThirdPartyPasswordless.ThirdPartyPasswordlessAuth>
								}
							/>
						</Routes>
					</div>
				</Router>
			</div>
		</SuperTokensWrapper>
	);
}

export default App;
